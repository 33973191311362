import React, { useEffect, useState } from "react";
import { ReservStepper } from "../../components";
import { Phone, Wathsapp, EmailIcon, nissan } from "../../assets/images";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IS_DRAWER, IS_DRAWER_PAY } from "../../constants/actions";
import { PICS_BASE_URL } from "../../components/config/keys";
import Rating from "@mui/material/Rating";
import { apiClient } from "../../actions/api";
import { useHistory } from "react-router-dom";
import { specificReservation } from "../../actions/reservation";
import CallIcon from "@mui/icons-material/Call";
import Loader from "react-js-loader";

export default function ReservStatusPage() {
  const { spec_res_loc } = useSelector((state) => state.reservation);
  const { user } = useSelector((state) => state.user);
  let step = spec_res_loc?.status_suivi == 0 ? 1 : spec_res_loc?.status_suivi;
  const dispatch = useDispatch();
  const [rateNote, setRate] = useState(0); //for rating
  const [commentText, setCommentText] = useState(""); //for rating
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { idReservation } = useParams();
  let phoneNumberD = "+2250712530418";

  const handlepay = (e) => {
    e.preventDefault();
    dispatch({ type: IS_DRAWER_PAY, payload: true });
  };

  const handleSendComment = (e) => {
    e.preventDefault();
    let formData = {
      user_id: user?.user?.id,
      annonce_id: spec_res_loc?.id_annonce,
      note: rateNote,
      comment: commentText,
      reservation_id: spec_res_loc?.id,
    };
    apiClient()
      .post("/avis/store", formData)
      .then((res) => {
        if (res?.data?.success) {
          history.push("/myreservations");
        }
      });
  };

  const handleGetFacture = (e) => {
    e.preventDefault();
    setLoading(true);
    apiClient()
      .get(`/generate-pdf/${idReservation}`)
      .then((res) => {
        if (res?.data?.success) {
          const link = document.createElement("a");

          link.href = PICS_BASE_URL + "/" + res.data.path;

          link.setAttribute("download", `facture-${idReservation}.pdf`);

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        } else {
          console.error("Failed to fetch PDF path.");
        }
      })
      .catch((error) => {
        // Handle any API call errors
        console.error("API call failed:", error);
      });
    setLoading(false);
  };

  const getSpecRes = () => {
    apiClient()
      .get(`/reservation/getspec/${idReservation}`)
      .then((res) => {
        if (res?.data?.success) {
          dispatch(specificReservation(res?.data?.reservation));
        }
      });
  };

  useEffect(() => {
    getSpecRes();

    const intervalId = setInterval(() => {
      getSpecRes();
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  const picsExterArray = JSON.parse(spec_res_loc?.annonce?.pics_exter || "[]");

  const msgData = [
    {
      index: 1,
      comp: (
        <>
          <p className="10min-par">
            En attente de l'approbation de l'annonceur . Délai d'attente entre{" "}
            <span>10 minutes - 1 heure</span>.
          </p>
        </>
      ),
    },
    {
      index: 2,
      comp: (
        <>
          <p className="docs-par">
            Veuillez finaliser votre paiement en ligne ou payer directement dans
            nos locaux :
          </p>
          <span>Abidjan , Cocody les 2 plateaux 7ème tranche, Orabank</span>
          <div className="docs">
            <p className="10min-par">
              Nous aurons également besoin de ces documents :
            </p>
            <br />
            <ul>
              <li>Un permis de conduire ( si vous conduirez le véhicule )</li>
              <li>Une carte d'identité</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      index: 3,
      comp: (
        <>
          <p>Pour la remise des clés du véhicule, vous avez deux options </p>
          <ul>
            <li>
              Récupération dans nos locaux{" "}
              <span>Abidjan , Cocody les 2 plateaux 7ème tranche, Orabank</span>{" "}
            </li>
            <li>
              Nous pouvons vous faire la livraison selon votre emplacement (
              Déplacement à vos frais 5.000 Frs )
            </li>
          </ul>
        </>
      ),
    },
    {
      index: 4,
      comp:
        spec_res_loc?.noted_commented === 1 ? (
          <div className="end-div">
            <span className="ajouter_title">Réservation terminée.</span>
            {/* <span>Réservation terminée .</span> */}
            {/* <p>Nous espérons que vous êtes satisfait de votre location</p> */}
            <Rating
              name="half-rating"
              value={spec_res_loc?.annonce?.avis[0]?.note}
              precision={1}
            />
            <input
              type="text"
              className="comment_section"
              value={spec_res_loc?.annonce?.avis[0]?.comment}
              disabled
            />
          </div>
        ) : (
          <div className="end-div">
            <span className="ajouter_title">
              Merci d'ajouter une note et un commentaire
            </span>
            {/* <span>Réservation terminée .</span> */}
            {/* <p>Nous espérons que vous êtes satisfait de votre location</p> */}
            <Rating
              name="half-rating"
              defaultValue={0}
              value={rateNote}
              precision={1}
              onChange={(event, newValue) => setRate(newValue || 0)}
            />
            <input
              onChange={(e) => setCommentText(e.target.value)}
              type="text"
              className="comment_section"
              placeholder="Ajouter un commentaire..."
            />
            <button
              className="send_comment"
              onClick={(e) => handleSendComment(e)}
            >
              Envoyer
            </button>
          </div>
        ),
    },
  ];

  const calculateDuree = (startDate, endDate) => {
    const diffInTime = new Date(endDate) - new Date(startDate);
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    return diffInDays;
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleCallButtonClick = () => {
    const phoneLink = `tel:${phoneNumberD}`;
    window.location.href = phoneLink;
  };

  const handleWhatsAppButtonClick = () => {
    const whatsappLink = `https://wa.me/${phoneNumberD}`;

    window.location.href = whatsappLink;
  };

  return (
    <>
      {loading && (
        <Loader
          type="spinner-circle"
          bgColor="#000"
          color="#fff"
          size={100}
          className="absolute-center-loader"
        />
      )}
      <div className="reserv-status-container">
        <h3>Suivi de la réservation</h3>
        <ReservStepper step={step} />
        <div className="reserv-msg-div">{msgData[step - 1]?.comp}</div>
        {/* {step == 2 &&
        (spec_res_loc?.mode_pay == "" ? (
          <button className="btn pay-btn" onClick={(e) => handlepay(e)}>
            {" "}
            Je paie ma facture en ligne
          </button>
        ) : (
          <button className="btn pay-btn" style={{backgroundColor: 'grey'}}>
            {" "}
            Payée
          </button>
        ))} */}
        {step == 2 && (
          <button className="btn pay-btn" onClick={(e) => handlepay(e)}>
            {" "}
            Je paie ma facture en ligne
          </button>
        )}
        <div className="help-div">
          <h3>Besoin d'aide ? Contactez-nous !</h3>
          <div className="help-types">
            <CallIcon
              style={{ width: "1.9em", height: "1.9em" }}
              onClick={handleCallButtonClick}
            />
            <img onClick={handleWhatsAppButtonClick} src={Wathsapp} alt="" />
            <img alt="help img" src={EmailIcon} />
          </div>
        </div>
        <div className="purple-part">
          <div className="purple-head">
            <h3>Détails de la réservation</h3>
            <span>ID : {spec_res_loc?.id}</span>
          </div>
          <div className="vh-infos-div">
            <div
              className="img-side"
              style={{
                backgroundImage: `url('${PICS_BASE_URL}/${picsExterArray[0]}')`,
              }}
            ></div>
            <div className="text-side">
              <span>{spec_res_loc?.annonce?.title}</span>
              <span>{spec_res_loc?.annonce?.price_day} Fcfa / jour</span>
            </div>
          </div>
          <div className="location-period days-div">
            <span>Durée de location</span>
            <span>
              {formatDate(spec_res_loc?.date_debut)} au{" "}
              {formatDate(spec_res_loc?.date_fin)}
            </span>
            <span>
              {calculateDuree(spec_res_loc?.date_debut, spec_res_loc?.date_fin)}{" "}
              jours
            </span>
          </div>
          {/* <div className="location-period"> */}
          {/* <span>Frais de commission Kleever</span> */}
          {/* <span>{spec_res_loc?.annonce?.pourcentage} %</span> */}
          {/* </div> */}
          <div className="total-to-pay">
            <span> Montant total versé (en ligne)</span>
            <div className="price-div">
              <span>
                {spec_res_loc?.annonce?.price_day *
                  calculateDuree(
                    spec_res_loc?.date_debut,
                    spec_res_loc?.date_fin
                  )}{" "}
                Fcfa
              </span>
              <span>{spec_res_loc?.prix} Fcfa</span>
            </div>
          </div>
          {spec_res_loc?.status_suivi == 3 && (
            <button
              className="btn purple-btn"
              onClick={(e) => handleGetFacture(e)}
            >
              Télécharger Ma Facture
            </button>
          )}
        </div>
      </div>
    </>
  );
}
